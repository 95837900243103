<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card-title class="xflex xflex-row xjustify-between xitems-center">
      <Filters 
        :value="selectedDate" 
        @change="navigate"
        @project-users-updated="fetch(selectedDate)"
        type="daily"
      >
      </Filters>
    </v-card-title>
    <v-card-text>
      <UsersTable
        :loading="loading"
        :loading-more="loading_more"
        :items="item ? item.summary : []"
      ></UsersTable>
      <!-- <pre>{{ item.summary}}</pre> -->
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import Filters from "../components/Filters.vue";
import UsersTable from "../components/UsersTable.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      tab: "projects", 
      loading: false,
      loading_more: false,
      item: null, 
      selectedDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: { 
    projectId() {
      return this.$route.params.project_id || null;
    },
  },
  watch: {
    "$route.params.date": {
      handler: function (val) {
        this.selectedDate = val;
        val && this.fetch(val);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("projects", ["storeProject"]),
    navigate(val) {
      this.$router
        .push({
          name: "app-project-daily",
          params: { date: val, project_id: this.projectId },
        })
        .catch(() => {});
    },
    fetch(date) {
      this.loading = true;
      this.$axios
        .get(`api/project-users/${this.projectId}/daily/${date}`)
        .then(({ data }) => {
          this.item = data;
          this.storeProject(data);
          this.$event.$emit(
            "project-count",
            this.item ? this.item.summary.length : 0
          );
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { Filters, UsersTable },
};
</script>

<style lang="scss" scoped></style>
